import { NgModule, LOCALE_ID, Inject } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, registerLocaleData, DOCUMENT } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MatNativeDateModule } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Router, NavigationEnd, ActivationStart } from '@angular/router';
import localeCs from '@angular/common/locales/cs';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { StoreModule, Store, select } from '@ngrx/store';
import {
  TranslateModule,
  TranslateCompiler,
  TranslateService
} from '@ngx-translate/core';
import { DialogModule } from '@zerops/fe/dialog';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { ErrorsModule, ProgressModule } from '@zerops/fe/ngrx';
import { PermissionService } from '@zerops/fe/permission';
import { MetaModule, META_CONFIG } from '@zerops/fe/meta';
import { NgxHotkeysModule } from '@balticcode/ngx-hotkeys';
import { ClipboardModule } from 'ngx-clipboard';
import { Angulartics2Module } from 'angulartics2';
import { filter, map, pairwise, debounce } from 'rxjs/operators';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { combineLatest } from 'rxjs';
import isEmpty from 'lodash-es/isEmpty';
import { RouterEffects } from 'ngrx-router';
import {
  AuthBaseModule,
  Roles,
  isAuthenticated,
  authActiveClientUser
} from '@app/base/auth-base';
import { UsersBaseModule } from '@app/base/users-base';
import { ClientUserBaseModule, ClientUserRoles } from '@app/base/client-user-base';
import { ServicesBaseModule } from '@app/base/services-base';
import { ClientsBaseModule } from '@app/base/clients-base';
import { ServerParkAccessBaseModule } from '@app/base/server-park-access-base';
import { InvoicesBaseModule } from '@app/base/invoices-base';
import { TicketsBaseModule } from '@app/base/tickets-base';
import { NgrxRouterModule } from '@app/common/ngrx-router';
import { FilesModule } from '@app/common/files';
import { WebsocketsModule } from '@app/common/websockets';
import { AppBarModule } from '@app/common/app-bar';
import { TicketsTriggerModule } from '@app/common/tickets-trigger';
import { SnackModule } from '@app/common/snack';
import { SettingsModule } from '@app/common/settings';
import { TicketsListModule } from '@app/common/tickets-list';
import { SearchModule } from '@app/common/search';
import { ServersBaseModule } from '@app/base/servers-base';
import { activeUserClientId } from '@app/base/auth-base/auth-base.selector';
import { BulkPaymentDialogModule } from '@app/common/bulk-payment-dialog';
import { DomainRoutingInfoDialogModule } from '@app/common/domain-routing-info-dialog';
import { PaymentsBaseModule } from '@app/base/payments-base';
import { ClientServicesBaseModule } from '@app/base/client-services-base';
import { TicketAddModule } from '@app/common/ticket-add';
import { DateFnsAdapterModule } from '@app/common/date-fns-adapter';
import { RecaptchaModule } from '@app/common/recaptcha';
import { DomainsBaseModule } from '@app/base/domains-base';
import { ClouddnsWebsocketsModule } from '@app/common/clouddns-websockets';
import { WsReconnectModule } from '@app/common/ws-reconnect';
import { KvmConsoleConnectionModule } from '@app/common/kvm-console-connection';
import { environment } from 'environments/environment';
import { sentryErrorProvider } from './services';
import { AppComponent } from './app.component';
import { metaReducers } from './reducers/meta-reducers';
import { APP_ROUTES } from './app.routes';
import { TRANSLATIONS } from './app.translations';
import { State } from './models';
import { errorNormalizerInterceptorProvider } from './interceptors';
import { apiInterceptorInterceptorProvider } from './interceptors/api-interceptor.interceptor';

registerLocaleData(localeCs);

const PROD_PROVIDERS = !environment.production
  ? [ ]
  : [
    ...sentryErrorProvider
  ];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    RouterModule.forRoot(APP_ROUTES),
    MatSnackBarModule,
    MatNativeDateModule,
    FlexLayoutModule,
    TranslateModule.forRoot({
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      }
    }),
    StoreModule.forRoot(
      { router: routerReducer },
      { metaReducers }
    ),
    EffectsModule.forRoot([
      RouterEffects
    ]),
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router'
    }),
    ClipboardModule,
    ErrorsModule.forRoot(),
    MetaModule.forRoot(),
    ProgressModule.forRoot(),
    DialogModule.forRoot(),
    NgxHotkeysModule.forRoot({ disableCheatSheet: true }),
    NgrxRouterModule,
    Angulartics2Module.forRoot(),
    AppBarModule,
    TicketsTriggerModule,
    AuthBaseModule,
    UsersBaseModule,
    ClientUserBaseModule,
    ServicesBaseModule,
    ClientsBaseModule,
    ServerParkAccessBaseModule,
    InvoicesBaseModule,
    SettingsModule,
    FilesModule,
    TicketsBaseModule,
    TicketsListModule,
    SnackModule,
    WebsocketsModule,
    ClouddnsWebsocketsModule,
    WsReconnectModule,
    KvmConsoleConnectionModule,
    TicketAddModule,
    SearchModule,
    ServersBaseModule,
    PaymentsBaseModule,
    ClientServicesBaseModule,
    BulkPaymentDialogModule,
    DomainRoutingInfoDialogModule,
    RecaptchaModule,
    DateFnsAdapterModule,
    DomainsBaseModule
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'cs'
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline'
      }
    },
    errorNormalizerInterceptorProvider,
    apiInterceptorInterceptorProvider,
    {
      provide: META_CONFIG,
      useValue: {
        baseTitle: 'vshosting'
      }
    },
    ...PROD_PROVIDERS
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule {
  constructor(
    private _router: Router,
    private _translate: TranslateService,
    private _permissions: PermissionService,
    private _store: Store<State>,
    @Inject(DOCUMENT)
    private _document: Document
  ) {

    this._document.body.classList.add('app-release-' + environment.release);

    const availableTranslations = [ 'en', 'cs', 'hu', 'de' ];

    // add global translations
    this._translate.setTranslation('en', TRANSLATIONS.en);
    this._translate.setTranslation('cs', TRANSLATIONS.cs);
    this._translate.setTranslation('hu', TRANSLATIONS.hu);
    this._translate.setTranslation('de', TRANSLATIONS.de);

    // use translation, later, if user is logged in or
    // authorized from existing token, language will be
    // switched to the one user has set in profile
    const defaultLang = availableTranslations.includes(this._translate.getBrowserLang())
      ? this._translate.getBrowserLang()
      : 'en';

    this._translate.use(defaultLang);

    // # Permissions Setup
    // base roles
    this._permissions.define(
      Roles.Authorized,
      () => combineLatest(
        this._store.pipe(select(isAuthenticated)),
        this._store.pipe(select(activeUserClientId))
      )
        .pipe(
          map(([ authenticated, id ]) => !!authenticated && !!id)
        )
    );

    this._permissions.define(
      Roles.Unauthorized,
      () => combineLatest(
        this._store.pipe(select(isAuthenticated)),
        this._store.pipe(select(activeUserClientId))
      )
        .pipe(
          map(([ authenticated, id ]) => !authenticated || !id)
        )
    );

    // user roles
    this._permissions.define(
      Roles.Manager,
      () => this._store.pipe(
        select(authActiveClientUser),
        map((userClient) => userClient && userClient.roleCode === ClientUserRoles.Manager)
      )
    );

    this._permissions.define(
      Roles.Technical,
      () => this._store.pipe(
        select(authActiveClientUser),
        map((userClient) => userClient && userClient.roleCode === ClientUserRoles.TechnicalUser)
      )
    );

    this._permissions.define(
      Roles.Financial,
      () => this._store.pipe(
        select(authActiveClientUser),
        map((userClient) => userClient && userClient.roleCode === ClientUserRoles.FinancialUser)
      )
    );

    // -- special
    this._permissions.define(
      Roles.ManagerWithSPAccess,
      () => this._store.pipe(
        select(authActiveClientUser),
        map((userClient) => userClient
          && userClient.roleCode === ClientUserRoles.Manager
          && userClient.client.hasAccessToServerPark
        )
      )
    );

    this._permissions.define(
      Roles.AuthorizedToSendTickets,
      () => this._store.pipe(
        select(authActiveClientUser),
        map((user) => !!user && !!user.authorizedToSendTickets)
      )
    );

    // # Various
    // scroll to top after route change
      this._router.events
        .pipe(
          map((event) => event as ActivationStart),
          filter(event => event instanceof ActivationStart),
          pairwise(),
          debounce(() => this._router.events.pipe(filter(event => event instanceof NavigationEnd))),
        )
        .subscribe(([ previous, next ]) => {
          const previousRouterState = getRouterState(previous);
          const previousPage = previousRouterState.params.page
            ? parseInt(previousRouterState.params.page, 10)
            : undefined;
          const nextRouterState = getRouterState(next);
          const nextPage = nextRouterState.params.page
            ? parseInt(nextRouterState.params.page, 10)
            : undefined;

          // do not scroll when changing query params of records
          if (isRoute('domains/detail/:id/records', nextRouterState.path)
            && isRoute('domains/detail/:id/records', previousRouterState.path)) {

            if (!isEmpty(nextRouterState.params) && (nextPage === previousPage || (!previousPage && !nextPage))) {
              return;
            }

            // params on new state are empty and previously they were not
            if (isEmpty(nextRouterState.params) && !isEmpty(previousRouterState.params)) {
              return;
            }
          }

          window.scrollTo(0, 0);

          setTimeout(() => {
            window.scrollTo(0, 0);
          });

        });

  }
}

function isRoute(route: string | string[] | RegExp, path: string) {
  if (Array.isArray(route)) {
    return route.indexOf(path) > -1;
  } else if (route instanceof RegExp) {
    return route.test(path);
  } else {
    return path === route;
  }
}

function getRouterState(event: ActivationStart) {
  let route = event.snapshot;
  const path: any[] = [];
  const { params, queryParams, data } = route;

  while (route.parent) {
    if (route.routeConfig && route.routeConfig.path) {
      path.push(route.routeConfig.path);
    }
    route = route.parent;
  }
  return { params, queryParams, data, path: path.reverse().join('/') };
}
