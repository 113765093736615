<div class="__price">

  <vshcz-price
    mode="bold-symbol"
    [amount]="total - credit"
    [currencyId]="currencyId"
    [currencyMap]="currencyMap"
    [priceSize]="34"
    [symbolSize]=13>
  </vshcz-price>

  <span
    *ngIf="!credit"
    class="__price_currency">
    / <span translate="bulkPayment.total"></span>
  </span>

</div>

<div
  *ngIf="credit && credit > 0"
  class="__credit">

  <span class="__credit-plus">
    +&nbsp;
  </span>

  <vshcz-price
    mode="bold"
    class="__credit-price"
    [amount]="credit"
    [currencyId]="currencyId"
    [currencyMap]="currencyMap"
    [priceSize]="18"
    [symbolSize]="12">
  </vshcz-price>
  
  <span
    *ngIf="mode === 'short'"
    translate="bulkPayment.creditShort">
  </span>

  <span
    *ngIf="mode === 'long'"
    translate="bulkPayment.creditLong">
  </span>

</div>
