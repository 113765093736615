import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { BaseClass } from '@zerops/fe/core';
import { getDialogState } from '@zerops/fe/dialog';
import { combineLatest } from 'rxjs';
import {
  shareReplay,
  map,
  takeUntil,
  filter,
  startWith
} from 'rxjs/operators';
import { State } from '@app/models';
import { unpaidInvoices, orderInvoices } from '@app/base/invoices-base';
import { activeUserClient } from '@app/base/auth-base/auth-base.selector';
import { getPaymentQR } from '@app/base/payments-base/payments-base.utils';
import { currencyMap } from '../settings';
import { DialogKey } from './bulk-payment-dialog.constant';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'vshcz-bulk-payment-dialog',
  templateUrl: './bulk-payment-dialog.container.html',
  styleUrls: [ './bulk-payment-dialog.container.scss' ],
})
export class BulkPaymentDialogContainer extends BaseClass {

  dialogKey = DialogKey;
  currencyId: string;
  iban: string;
  swift: string;
  bankAccount: string;
  varSymbol: number;
  qrData: string;
  bankName: string;

  currencyMap$ = this._store.pipe(
    select(currencyMap),
    shareReplay()
  );

  unpaidInvoices$ = this._store.pipe(
    select(unpaidInvoices),
    map((invoices) => orderInvoices(invoices, false))
  );

  firstUnpaidInvoice$ = this.unpaidInvoices$.pipe(
    map((invoices) => invoices && invoices.length
      ? invoices[0]
      : undefined
    )
  );

  total$ = this.unpaidInvoices$.pipe(
    map((invoices) => !!invoices && !!invoices.length
      ? invoices.reduce((sum, inv) => {
          return sum + inv.totalDue;
        }, 0)
      : 0
    )
  );

  open$ = this._store.pipe(
    select(getDialogState(this.dialogKey)),
    map(({ state }) => !!state)
  );

  client$ = this._store.pipe(
    select(activeUserClient)
  );

  credit$ = this.client$.pipe(
    map((client) => client && client.creditVat
      ? client.creditVat
      : 0
    )
  );

  currentLang$ = this._translate.onLangChange.pipe(
    startWith(this._translate.currentLang),
    map(() => this._translate.currentLang)
  );

  isCz$ = combineLatest(
    this.client$,
    this.firstUnpaidInvoice$
  )
    .pipe(
      map(([ client, invoice ]) => (!!invoice && invoice.currencyId === 'CZK')
        && (!!client && !!client.invoiceAddress && client.invoiceAddress.countryId === 'CZ')
      ),
      shareReplay()
    );

  constructor(
    private _store: Store<State>,
    private _translate: TranslateService,
    ) {
    super();

    combineLatest(
      this.firstUnpaidInvoice$,
      this.total$,
      this.client$,
      this.isCz$,
      this.credit$
    )
      .pipe(
        takeUntil(this._ngOnDestroy$),
        filter(([ invoice, total, client ]) => !!invoice && !!total && !!client)
      )
      .subscribe(([ invoice, total, client, isCz, credit ]) => {
        // set sync data
        this.varSymbol = client.invoiceVariableSymbol;
        this.currencyId = invoice.currencyId;
        this.iban = invoice.contractorBankAccount.iban;
        this.swift = invoice.contractorBankAccount.swift;
        this.bankAccount = invoice.contractorBankAccount.localAccountNumber;
        this.bankName = invoice.contractorBankAccount.bankName;

        this.qrData = getPaymentQR(
          this.iban,
          +(total - credit).toFixed(2),
          this.currencyId,
          isCz ? this.varSymbol : null,
          this.swift,
          isCz ? '' : `VS/${this.varSymbol}`
        );
      });
  }
}
