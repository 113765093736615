import { normalize, denormalize } from 'normalizr';
import orderBy from 'lodash-es/orderBy';
import { invoicesListSchema, invoicesSchema } from '@app/schemas';
import { ModuleTokens, INVOICE_ORDER_PRIORITY_MAP } from './invoices-base.constant';
export function normalizeList(data) {
    return normalize(data, invoicesListSchema);
}
export function denormalizeList(ids, invoicesEntities) {
    var _a;
    return denormalize(ids, invoicesListSchema, (_a = {},
        _a[ModuleTokens.Name] = invoicesEntities,
        _a));
}
export function denormalizeEntity(id, invoicesEntities) {
    var _a;
    return denormalize(id, invoicesSchema, (_a = {},
        _a[ModuleTokens.Name] = invoicesEntities,
        _a));
}
export var orderInvoices = function (invoices, orderByDue, dueDateDesc) {
    if (orderByDue === void 0) { orderByDue = true; }
    if (dueDateDesc === void 0) { dueDateDesc = 'desc'; }
    var orderKeys = orderByDue
        ? ['dueDate']
        : ['number'];
    var orderOrder = orderByDue
        ? [dueDateDesc]
        : ['desc'];
    return orderBy(invoices, [function (i) { return INVOICE_ORDER_PRIORITY_MAP[i.status]; }].concat(orderKeys), ['desc'].concat(orderOrder));
};
