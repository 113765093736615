<div class="__wrap" (mouseleave)="onLeave()">

  <ul class="c-inline-list  __list">

    <li
      class="c-inline-list__item  __list-item"
      *ngIf="showBilling$ | async">
      <a
        mat-button
        vshczMenuItem
        (entered)="onEntered($event, 1, true)"
        (mouseleave)="onButtonLeave()"
        [class.is-active]="menuPopOpen && activeIndex === 1"
        [matBadge]="overdueInvoicesCount || undefined"
        matBadgePosition="below after"
        matBadgeColor="warn"
        [class.is-compact]="media.isActive('lt-lg')"
        class="__item  __item--beta  __list-item"
        [class.is-hu]="(currentLang$ | async) === 'hu'"
        [routerLink]="[ '/', 'invoices', 'list' ]">
        <!-- not in span to prevent pop box arrow to go uncentrated -->
        {{ 'menuItems.billing' | translate }}
      </a>
    </li>

    <li
      class="c-inline-list__item  __list-item"
      *ngIf="showServices$ | async">
      <a
        mat-button
        vshczMenuItem
        [class.is-active]="menuPopOpen && activeIndex === 0"
        [class.is-hu]="(currentLang$ | async) === 'hu'"
        (entered)="onEntered($event, 0, true)"
        (mouseleave)="onButtonLeave()"
        [matBadge]="servicesCount || undefined"
        matBadgePosition="below after"
        matBadgeColor="accent"
        class="__item  __item--alpha"
        [routerLink]="[ '/client-services' ]">
        <!-- not in span to prevent pop box arrow to go uncentrated -->
        {{ 'menuItems.services' | translate }}
      </a>
    </li>

    <li
      class="c-inline-list__item  [ __list-item  __list-item--last ]"
      *ngIf="showCompany$ | async">
      <a
        mat-button
        vshczMenuItem
        [class.is-active]="menuPopOpen && activeIndex === 2"
        [class.is-hu]="(currentLang$ | async) === 'hu'"
        (entered)="onEntered($event, 2, true)"
        (mouseleave)="onButtonLeave()"
        (click)="menuPopOpen = false"
        class="__item  __item--gamma"
        [routerLink]="[ '/company' ]">
        <!-- not in span to prevent pop box arrow to go uncentrated -->
        {{ 'menuItems.company' | translate }}
      </a>
    </li>

  </ul>
</div>

<vshcz-displacer (initialized)="menuPopRef.refresh()">
  <vshcz-menu-pop
    #menuPopRef
    (leave)="onLeave()"
    (mouseenter)="onEntered(coordinates, activeIndex)"
    (close)="menuPopOpen = false"
    [showSettings]="showGraphsSettingsAndExternal$ | async"
    [showFinancial]="showFinancialAndStatus$ | async"
    (companySelectToggle)="companySelectOpened = $event"
    [open]="menuPopOpen || companySelectOpened"
    [coordinates]="coordinates"
    [activeIndex]="activeIndex">
  </vshcz-menu-pop>
</vshcz-displacer>
