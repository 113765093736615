import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import differenceInDays from 'date-fns/esm/differenceInDays';
import startOfDay from 'date-fns/esm/startOfDay';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var InvoicesBaseApi = /** @class */ (function () {
    function InvoicesBaseApi(_http) {
        this._http = _http;
    }
    // TODO: remove mapping after Cron has been implemented
    InvoicesBaseApi.prototype.list$ = function (clientId) {
        return this._http.get("/api/public/client/" + clientId + "/invoice").pipe(map(function (response) { return ({
            invoiceList: response.invoiceList.map(function (i) { return (tslib_1.__assign({}, i, { status: i.status === 'WAITING_FOR_PAYMENT'
                    && new Date(i.dueDate).getTime() < new Date().getTime()
                    ? 'PAYMENT_OVERDUE'
                    : i.status, daysOverdue: Math.abs(differenceInDays(startOfDay(i.dueDate), startOfDay(new Date()))) })); })
        }); }));
    };
    // TODO: remove mapping after Cron has been implemented
    InvoicesBaseApi.prototype.entity$ = function (id) {
        return this._http.get("/api/public/invoice/" + id).pipe(map(function (i) { return (tslib_1.__assign({}, i, { status: i.status === 'WAITING_FOR_PAYMENT'
                && new Date(i.dueDate).getTime() < new Date().getTime()
                ? 'PAYMENT_OVERDUE'
                : i.status, daysOverdue: Math.abs(differenceInDays(startOfDay(i.dueDate), startOfDay(new Date()))) })); }));
    };
    InvoicesBaseApi.prototype.createPdf$ = function (id) {
        return this._http.get("/api/public/invoice/" + id + "/create-pdf");
    };
    InvoicesBaseApi.ngInjectableDef = i0.defineInjectable({ factory: function InvoicesBaseApi_Factory() { return new InvoicesBaseApi(i0.inject(i1.HttpClient)); }, token: InvoicesBaseApi, providedIn: "root" });
    return InvoicesBaseApi;
}());
export { InvoicesBaseApi };
