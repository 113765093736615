import * as tslib_1 from "tslib";
import { createFeatureSelector, createSelector } from '@ngrx/store';
import uniq from 'lodash-es/uniq';
// intentionally explicit because of dependency cycle errors
import { entities as clientUserEntities } from '../client-user-base/client-user-base.selector';
import { ModuleTokens as ClientUserBaseModuleTokens } from '../client-user-base/client-user-base.constant';
import { clientsBaseEntities, ClientsBaseModuleTokens, denormalizeClientEntity } from '@app/base/clients-base';
import { servicesEntities, ServicesBaseModuleTokens, ServiceCodes } from '@app/base/services-base';
import { usersEntities, denormalizeUserEntity } from '@app/base/users-base';
import { ModuleTokens } from './auth-base.constant';
import { isKvm } from '../servers-base/servers-base.utils';
export var slice = createFeatureSelector(ModuleTokens.Name);
export var isAuthenticated = createSelector(slice, function (s) { return s.authorized; });
export var userId = createSelector(slice, function (s) { return s.userId; });
export var authorType = createSelector(slice, function (s) { return s.authorType; });
export var cloudDnsRoleCode = createSelector(slice, function (s) { return s.cloudDnsRoleCode; });
export var activeUserClientId = createSelector(slice, function (s) { return s.clientUserId; });
export var referer = createSelector(slice, function (s) { return s.referer; });
export var activeUserNormalizedClient = createSelector(activeUserClientId, clientsBaseEntities, function (id, ents) { return ents && ents[ClientsBaseModuleTokens.Name]
    ? ents[ClientsBaseModuleTokens.Name][id]
    : undefined; });
export var activeUserClient = createSelector(activeUserClientId, clientsBaseEntities, servicesEntities, function (id, ents, servicesEnt) {
    return denormalizeClientEntity(id, ents[ClientsBaseModuleTokens.Name], servicesEnt[ServicesBaseModuleTokens.Name]);
});
export var activeUserServices = createSelector(activeUserClient, function (client) { return client ? client.connectedServiceList : []; });
var allManaged = [
    'MANAGED_SERVER',
    'CLOUD_MANAGED_SERVER',
    'MANAGED_CLUSTER',
    'M1_MANAGED_SERVER',
    'M2_MANAGED_SERVER',
    'MANAGED_WINDOWS_SERVER',
    'MANAGED_WINDOWS_CLUSTER',
    'MANAGED_WINDOWS_BASIC',
    'MANAGED_WINDOWS_CLOUD',
    'AWS_MANAGED_SERVER'
];
var managedAliasMap = {
    MANAGED_SERVER: 'managed-server',
    M1_MANAGED_SERVER: 'm1-managed-server',
    M2_MANAGED_SERVER: 'm2-managed-server',
    MANAGED_WINDOWS_SERVER: 'windows-managed-server',
    MANAGED_WINDOWS_CLUSTER: 'windows-managed-cluster',
    MANAGED_WINDOWS_BASIC: 'windows-managed-basic',
    MANAGED_WINDOWS_CLOUD: 'windows-managed-cloud',
    CLOUD_MANAGED_SERVER: 'cloud-managed-server',
    MANAGED_CLUSTER: 'managed-cluster',
    AWS_MANAGED_SERVER: 'aws-managed-server'
};
export var activeManagedServicesAliases = createSelector(activeUserClient, function (client) {
    if (!client || !client.connectedServiceList) {
        return [];
    }
    var s = client
        .connectedServiceList
        .filter(function (item) { return !![
        'ACTIVE',
        'WAITING_FOR_TERMINATION'
    ].includes(item.clientZoneStatus); })
        .filter(function (item) { return item.standardService && item.serviceType === 'STANDARD'; })
        .filter(function (item) { return allManaged.includes(item.standardService.code); })
        .map(function (item) { return item.standardService.code; })
        .map(function (item) { return managedAliasMap[item]; });
    return uniq(s);
});
export var activeUserServicesActiveGrouped = createSelector(activeUserClient, function (client) {
    var serviceMap = {};
    if (!client || !client.connectedServiceList) {
        return [];
    }
    return client
        .connectedServiceList
        .filter(function (item) { return !![
        'ACTIVE',
        'WAITING_FOR_TERMINATION'
    ].includes(item.clientZoneStatus); })
        .filter(function (item) { return item.standardService && item.serviceType === 'STANDARD'; })
        .reduce(function (arr, item) {
        var code = allManaged.includes(item.standardService.code)
            ? ServiceCodes.allManaged
            : item.standardService.code;
        if (serviceMap[code] === undefined) {
            arr.push({
                key: code,
                items: []
            });
            serviceMap[code] = arr.length - 1;
        }
        arr[serviceMap[code]].items = arr[serviceMap[code]].items.concat([
            tslib_1.__assign({}, item, { _isKvm: isKvm(code) })
        ]);
        return arr;
    }, []);
});
export var identity = createSelector(isAuthenticated, userId, clientUserEntities, clientsBaseEntities, usersEntities, servicesEntities, function (auth, id, clientUserEnt, clientsEnt, usersEnt, servicesEnt) {
    if (!auth || !id) {
        return undefined;
    }
    return denormalizeUserEntity(id, usersEnt.usersBase, clientUserEnt[ClientUserBaseModuleTokens.Name], clientsEnt[ClientsBaseModuleTokens.Name], servicesEnt[ServicesBaseModuleTokens.Name]);
});
export var activeUserClientUsers = createSelector(identity, function (idnt) { return idnt ? idnt.clientUserList : []; });
export var activeClientUser = createSelector(activeUserClientId, identity, function (cId, user) {
    if (!cId || !user) {
        return undefined;
    }
    var index = user.clientUserList.findIndex(function (item) { return item.clientId === cId; });
    return !!index || index === 0
        ? user.clientUserList[index]
        : undefined;
});
