import { ServiceEntity } from '@app/base/services-base';
import { ClientUserEntity } from '@app/base/client-user-base';
import { ModuleTokens } from './clients-base.constant';

export class ClientsBaseState {
  entities: ClientsEntityEntities;

  constructor() {
    this.entities = {
      [ModuleTokens.Name]: { }
    };
  }
}

export class ClientsEntityEntities {
  [ModuleTokens.Name]: { [id: string]: ClientEntityNormalized; };
}

export class ClientsEntityNormalizedData {
  entities: ClientsEntityEntities;
  result: string[];
}

export class InvoiceAdress {
  city: string;
  companyName: string;
  countryId: string;
  postcode: string;
  street: string;
}

export class DeliveryAddress {
  city: string;
  companyName: string;
  countryId: string;
  postcode: string;
  street: string;
}

// TODO: interface
export class Salesman {
  fullName: string;
  countryCallingCode: string;
  phoneNumber: string;
  email: string;
}

export class ClientEntityLight {
  id: string;
  companyName: string;
  webUrl: string;
  status: string;
  hasAccessToServerPark: boolean;
  cloudDnsEnabled: boolean;
  cdnEnabled: boolean;
  cloudMailEnabled: boolean;
  companyNumber: number;
  vatNumber: string;
  secureCommunication: boolean;
}

export class ClientEntityBase extends ClientEntityLight {
  vatCountryId: string;
  vatMode: string;
  vatRate: number;
  currencyId: string;
  languageId: string;
  invoiceAddress: InvoiceAdress;
  deliveryAddress: DeliveryAddress;
  salesman: Salesman;
  invoiceVariableSymbol: number;
  feeVariableSymbol: number;
  creditVat: number;
}

export class ClientEntityNormalized extends ClientEntityBase {
  connectedServiceList: string[];
  connectedUserList?: string[];
}

export class ClientEntity extends ClientEntityBase {
  connectedServiceList: ServiceEntity[];
  connectedUserList?: ClientUserEntity[];
}
