var _a;
export var InvoiceStatuses;
(function (InvoiceStatuses) {
    InvoiceStatuses["PaymentOverdue"] = "PAYMENT_OVERDUE";
    InvoiceStatuses["WaitingForPayment"] = "WAITING_FOR_PAYMENT";
    InvoiceStatuses["Paid"] = "PAID";
    InvoiceStatuses["Canceled"] = "CANCELED";
})(InvoiceStatuses || (InvoiceStatuses = {}));
export var ModuleTokens;
(function (ModuleTokens) {
    ModuleTokens["Name"] = "invoicesBase";
})(ModuleTokens || (ModuleTokens = {}));
export var InvoiceTypes;
(function (InvoiceTypes) {
    InvoiceTypes["clientServices"] = "CLIENT_SERVICES";
    InvoiceTypes["credit"] = "CREDIT";
    InvoiceTypes["fee"] = "FEE";
})(InvoiceTypes || (InvoiceTypes = {}));
export var INVOICE_ORDER_PRIORITY_MAP = (_a = {},
    _a[InvoiceStatuses.PaymentOverdue] = 2,
    _a[InvoiceStatuses.WaitingForPayment] = 1,
    _a[InvoiceStatuses.Paid] = 0,
    _a[InvoiceStatuses.Canceled] = 0,
    _a);
