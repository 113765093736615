<div
  fxLayout="row wrap"
  fxLayoutAlign="start start"
  fxLayoutAlign.lt-sm="center center"
  fxLayoutGap="30px grid">

  <div
    [fxFlex]="credit && credit > 0 
      ? '180px'
      : '157px'"
    fxFlex.lt-sm="100"
    class="u-text--center">
    <ngx-qrcode
      class="__qr"
      [qrc-value]="qrData">
    </ngx-qrcode>
  </div>

  <div
    fxFlex
    fxFlex.lt-sm="100"
    [class.u-text--center]="media.isActive('lt-sm')">
    <h5
      class="__title  __title--no-left-padding"
      [innerHtml]="'bulkPayment.overviewTitle' | translate: { count: unpaidCount }">
    </h5>

    <span class="__price">

      <vshcz-bulk-payment-price
        [currencyId]="currencyId"
        [currencyMap]="currencyMap"
        [credit]="credit"
        [total]="total">
      </vshcz-bulk-payment-price>

    </span>

    <button
      mat-flat-button
      [zpsNgrxDialogOpen]="dialogKey">
      <mat-icon>assignment</mat-icon> <span translate="bulkPayment.dialogTriggerButton"></span>
    </button>

  </div>

</div>
