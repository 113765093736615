import { Component, Input } from '@angular/core';
import { ObservableMedia } from '@angular/flex-layout';
import { Currency } from '../settings';
import { BulkPaymentDialogKey } from '../bulk-payment-dialog';
import { HashMap } from 'utils';

@Component({
  selector: 'vshcz-bulk-payment-overview',
  templateUrl: './bulk-payment-overview.component.html',
  styleUrls: [ './bulk-payment-overview.component.scss' ],
})
export class BulkPaymentOverviewComponent {

  dialogKey = BulkPaymentDialogKey;

  @Input()
  unpaidCount: number;

  @Input()
  total: number;

  @Input()
  currencyId: string;

  @Input()
  currencyMap: HashMap<Currency>;

  @Input()
  qrData: string;

  @Input()
  credit: number;

  constructor(public media: ObservableMedia) { }
}
