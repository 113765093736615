import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import differenceInDays from 'date-fns/esm/differenceInDays';
import startOfDay from 'date-fns/esm/startOfDay';
import { InvoiceEntityLight, InvoiceEntity } from './invoices-base.model';

@Injectable({
  providedIn: 'root'
})
export class InvoicesBaseApi {
  constructor(private _http: HttpClient) { }

  // TODO: remove mapping after Cron has been implemented
  list$(clientId: string) {
    return this._http.get<{ invoiceList: InvoiceEntityLight[] }>(
      `/api/public/client/${clientId}/invoice`
    ).pipe(
      map((response) => ({
        invoiceList: response.invoiceList.map((i) => ({
          ...i,
          status: i.status === 'WAITING_FOR_PAYMENT'
            && new Date(i.dueDate).getTime() < new Date().getTime()
            ? 'PAYMENT_OVERDUE'
            : i.status,
          daysOverdue: Math.abs(differenceInDays(
            startOfDay(i.dueDate),
            startOfDay(new Date())
          ))
        }))
      }))
    );
  }

  // TODO: remove mapping after Cron has been implemented
  entity$(id: string) {
    return this._http.get<InvoiceEntity>(
      `/api/public/invoice/${id}`
    ).pipe(
      map((i) => ({
        ...i,
        status: i.status === 'WAITING_FOR_PAYMENT'
          && new Date(i.dueDate).getTime() < new Date().getTime()
          ? 'PAYMENT_OVERDUE'
          : i.status,
        daysOverdue: Math.abs(differenceInDays(
          startOfDay(i.dueDate),
          startOfDay(new Date())
        ))
      }))
    );
  }

  createPdf$(id: string) {
    return this._http.get<{ token: string }>(
      `/api/public/invoice/${id}/create-pdf`
    );
  }

}
