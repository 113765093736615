<div
  #data
  [ngStyle]="{
    transform: contentOffset
      ? 'translate3d(' + contentOffset + 'px, 0px, 0px)'
      : undefined,
    'width.px': _sizeMap[activeIndex]
      ? _sizeMap[activeIndex].width
      : undefined,
    'height.px': _sizeMap[activeIndex]
      ? _sizeMap[activeIndex].height
      : undefined
  }"
  class="__data">

  <div
    class="__data-item"
    [ngClass]="{
      'is-active': activeIndex === 0,
      'is-left': activeIndex > 0
    }">
    <div #item class="__services  __data-content">
      <vshcz-services-card
        [settingsLayout]="40"
        [financialLayout]="60"
        [showSettings]="showSettings"
        [showFinancial]="showFinancial"
        (contentUpdated)="refresh()"
        (contentClicked)="close.emit()">
      </vshcz-services-card>
    </div>
  </div>


  <div
    class="__data-item"
    [ngClass]="{
      'is-active': activeIndex === 1,
      'is-right': activeIndex < 1,
      'is-left': activeIndex > 1
    }">
    <div #item class="__billing  __data-content">
      <div class="__content __billing_content">
        <mat-divider></mat-divider>
        <vshcz-billing-card
          (contentUpdated)="refresh()"
          (contentClicked)="close.emit()">
        </vshcz-billing-card>
      </div>
    </div>
  </div>

  <div
    class="__data-item"
    [ngClass]="{
      'is-active': activeIndex === 2,
      'is-right': activeIndex < 2
    }">
    <div #item class="__account  __data-content">
      <div class="__content  __account_content">
        <vshcz-company-card
          mode="vertical"
          (companySelectToggle)="companySelectToggle.emit($event)"
          (contentUpdated)="refresh()"
          (contentClicked)="close.emit()">
        </vshcz-company-card>
      </div>
    </div>
  </div>

</div>

<div
  #bg
  class="__bg"
  [ngStyle]="{
    'width.px': baseSizes.width,
    'height.px': baseSizes.height,
    transform: contentOffset && _sizeMap[activeIndex]
      ? 'translate3d(' + contentOffset + 'px, 0px, 0px) scaleX(' + bgScaleX + ') scaleY(' + bgScaleY + ')'
      : undefined
  }">
</div>

<div
  #arrow
  class="__arrow"
  [ngStyle]="{
    'width.px': _sizeMap[activeIndex]
      ? _sizeMap[activeIndex].width
      : undefined,
    transform: coordinates && _sizeMap[activeIndex]
      ? 'translate3d(' + (coordinates.xCenter - (_sizeMap[activeIndex].width / 2)) + 'px, 0px, 0px)'
      : undefined
  }">
</div>
