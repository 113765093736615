import { ClientEntityLight } from '@app/base/clients-base';
import { ModuleTokens } from './invoices-base.constant';

// invoice
export class InvoicesBaseState {
  entities: InvoiceEntityEntities;

  list: string[];

  constructor() {
    this.entities = {
      [ModuleTokens.Name]: { }
    };

    this.list = undefined;
  }
}

export class InvoiceEntityEntities {
  [ModuleTokens.Name]: { [id: string]: InvoiceEntityNormalized; };
}

export class InvoiceEntityNormalizedData {
  entities: InvoiceEntityEntities;
  result: string[];
}

export class InvoiceEntityLight {
  id: string;
  clientId: string;
  status: string;
  contractorId: string;
  number: number;
  created: string;
  dueDate: string;
  paidDate: string;
  total: number;
  totalCzk: number;
  vatAmount: number;
  vatAmountCzk: number;
  totalDue: number;
  totalVat: number;
  totalVatCzk: number;
  currencyConversionRate: number;
  currencyId: string;
  daysOverdue: number;
  type: string;
  contractorBankAccount: any;
}

export class InvoiceEntityBase extends InvoiceEntityLight {
  client: ClientEntityLight;
  // TODO: interfaces
  clientAddress: any;
  contractor: ContractorEntityBase;
  contractorAddress: any;
}

export class InvoiceEntityNormalized extends InvoiceEntityBase {
  invoiceItemList: string[];
}

export class InvoiceEntity extends InvoiceEntityBase {
  invoiceItemList: InvoiceItemEntityLight[];
}

// invoice item
export class InvoiceItemEntityLight {
  id: string;
  invoiceId: string;
  clientServiceId: string;
  type: string;
  itemName: string;
  itemIdentification: string;
  currencyId: string;
  amount: number;
  unitPrice: number;
  vatRate: number;
}

export class InvoiceItemEntityBase extends InvoiceItemEntityLight {
  billingPeriodStart: string;
  billingPeriodEnd: string;
  isReverseCharged: boolean;
  invoiceCustomItemId: string;
}

// contractor
export class ContractorEntityLight {
  id: string;
  companyName: string;
  isActive: boolean;
  companyNumber: string;
  vatNumber: string;
}

export class ContractorEntityBase extends ContractorEntityLight {
  created: string;
  lastUpdate: string;
  // TODO: interface
  invoiceAddress: any;
}
