import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { PermissionService } from '@zerops/fe/permission';
import { map, withLatestFrom, switchMap, catchError, delay, filter, first } from 'rxjs/operators';
import { ErrorTranslationService } from '@app/services';
import { authActiveUserClientId, AuthorizedRunEffect, Roles } from '@app/base/auth-base';
import { InvoicesBaseApi } from './invoices-base.api';
import { ListRequest, ActionTypes, ListLocalSuccess, ListFail, EntityLocalSuccess, EntityFail } from './invoices-base.action';
var InvoicesBaseEffect = /** @class */ (function (_super) {
    tslib_1.__extends(InvoicesBaseEffect, _super);
    function InvoicesBaseEffect(_actions$, _store, _api, _errorTranslation, _permission) {
        var _this = _super.call(this, _actions$, _store) || this;
        _this._actions$ = _actions$;
        _this._store = _store;
        _this._api = _api;
        _this._errorTranslation = _errorTranslation;
        _this._permission = _permission;
        // trigger list request on load
        _this._onInitList$ = _this.onAuthorizedInit$.pipe(delay(1), switchMap(function () { return _this._permission
            .authorize({
            only: [Roles.Manager, Roles.Financial]
        })
            .pipe(first()); }), filter(function (authorized) { return !!authorized; }), map(function () { return new ListRequest(); }));
        _this._onListRequest$ = _this._actions$.pipe(ofType(ActionTypes.ListRequest), withLatestFrom(_this._store.pipe(select(authActiveUserClientId))), switchMap(function (_a) {
            var _ = _a[0], id = _a[1];
            return _this._api
                .list$(id)
                .pipe(map(function (_a) {
                var invoiceList = _a.invoiceList;
                return new ListLocalSuccess(invoiceList);
            }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new ListFail(data); })); }));
        }));
        _this._onEntityRequest$ = _this._actions$.pipe(ofType(ActionTypes.EntityRequest), switchMap(function (_a) {
            var payload = _a.payload;
            return _this._api
                .entity$(payload)
                .pipe(map(function (response) { return new EntityLocalSuccess(response, payload); }), catchError(function (err) { return _this._errorTranslation
                .get$(err)
                .pipe(map(function (data) { return new EntityFail(data, payload); })); }));
        }));
        return _this;
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], InvoicesBaseEffect.prototype, "_onInitList$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], InvoicesBaseEffect.prototype, "_onListRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], InvoicesBaseEffect.prototype, "_onEntityRequest$", void 0);
    return InvoicesBaseEffect;
}(AuthorizedRunEffect));
export { InvoicesBaseEffect };
