import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input
} from '@angular/core';
import { BaseClass } from '@zerops/fe/core';
import { select, Store } from '@ngrx/store';
import { map, shareReplay, filter, takeUntil } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import orderBy from 'lodash-es/orderBy';
import { State } from '@app/models';
import { paymentsListEntities } from '@app/base/payments-base';
import {
  invoicesListEntities,
  unpaidInvoices,
} from '@app/base/invoices-base';
import { currencyMap } from '@app/common/settings';
import { getPaymentQR } from '@app/base/payments-base/payments-base.utils';
import { activeUserClient } from '@app/base/auth-base/auth-base.selector';

@Component({
  selector: 'vshcz-billing-card',
  templateUrl: './billing-card.container.html',
  styleUrls: [ './billing-card.container.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BillingCardContainer extends BaseClass {

  // # Data
  @Input()
  leftLayout: number | string;

  @Input()
  rightLayout: number | string;

  // -- async
  latestPayments$ = this._store.pipe(
    select(paymentsListEntities),
    map((payments) => orderBy(payments, [ 'created' ], [ 'desc' ]).slice(0, 5))
  );

  latestInvoices$ = this._store.pipe(
    select(invoicesListEntities),
    map(invoices => {
      if (invoices) {
        return invoices.slice(0, 5);
      }
      return invoices;
    })
  );

  unpaidInvoices$ = this._store.pipe(
    select(unpaidInvoices)
  );

  total$ = this.unpaidInvoices$.pipe(
    filter((d) => !!d),
    map((invoices) => invoices.reduce((sum, inv) => sum + inv.totalDue, 0))
  );

  client$ = this._store.pipe(
    select(activeUserClient)
  );

  credit$ = this.client$.pipe(
    map((client) => client && client.creditVat
      ? client.creditVat
      : 0
    )
  );

  qrData$ = combineLatest(
    this.unpaidInvoices$.pipe(
      map((invoices) => !!invoices && !!invoices.length ? invoices[0] : undefined)
    ),
    this.total$,
    this.client$,
    this.credit$
  )
    .pipe(
      map(([ invoice, total, client, credit ]) => !!invoice && !!total && !!client
        ? getPaymentQR(
          invoice.contractorBankAccount.iban,
          +(total - credit).toFixed(2),
          invoice.currencyId,
          invoice.currencyId === 'CZK' && client.invoiceAddress.countryId === 'CZ'
            ? client.invoiceVariableSymbol
            : null,
          invoice.contractorBankAccount.swift,
          invoice.currencyId === 'CZK' && client.invoiceAddress.countryId === 'CZ'
            ? ''
            : `VS/${client.invoiceVariableSymbol}`
        )
        : ''
      )
    );

  currencyMap$ = this._store.pipe(
    select(currencyMap),
    shareReplay()
  );

  // -- angular
  @Output()
  contentUpdated = new EventEmitter<void>();

  @Output()
  contentClicked = new EventEmitter<void>();

  constructor(
    private _store: Store<State>
  ) {
    super();

    // emit that content changed so menu can resize the pop
    combineLatest(
      this.latestInvoices$,
      this.latestPayments$
    )
      .pipe(takeUntil(this._ngOnDestroy$))
      .subscribe(() => this.contentUpdated.emit());
  }

  _trackBy(index: number) {
    return index;
  }
}
