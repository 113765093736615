<zps-dialog
  class="__dialog"
  [open]="open$ | async">
  <div class="__content">

    <vshcz-content-title
      size="big"
      position="center"
      class="__title">
      <span [innerHtml]="'bulkPayment.dialogTitle'
        | translate: { count: (unpaidInvoices$ | async)?.length }">
      </span>
    </vshcz-content-title>

    <span
      class="__info"
      translate="bulkPayment.dialogText">
    </span>

    <ngx-qrcode
      class="__qr"
      [qrc-value]="qrData">
    </ngx-qrcode>

    <div
      fxLayout="row wrap"
      fxLayoutAlign="center start"
      fxLayoutGap="24px grid">

      <div
        fxFlex="50"
        fxFlex.xs="80"
        class="__payment-info">

        <span
          class="__sub-title"
          translate="bulkPayment.info">
        </span>

        <span class="__price">

          <vshcz-bulk-payment-price
            mode="short"
            [currencyId]="currencyId"
            [currencyMap]="currencyMap$ | async"
            [credit]="credit$ | async"
            [total]="total$ | async">
          </vshcz-bulk-payment-price>

        </span>

        <span class="__info_item" *ngIf="isCz$ | async">
          <span
            class="__info_item_title"
            [class.is-hu]="(currentLang$ | async) === 'hu'"
            translate="bulkPayment.id">
          </span>
          <span class="__info_item_text">{{ varSymbol }}</span>
        </span>

        <ng-container *ngIf="!(isCz$ | async); else accountRef">

          <span class="__info_item">
            <span
            class="__info_item_title"
            [class.is-hu]="(currentLang$ | async) === 'hu'">
            IBAN
            </span>
            <span class="__info_item_text">{{ iban }}</span>
          </span>

          <span class="__info_item">
            <span
              class="__info_item_title"
              [class.is-hu]="(currentLang$ | async) === 'hu'">
              SWIFT
            </span>
            <span class="__info_item_text">{{ swift }}</span>
          </span>

        </ng-container>

        <ng-template #accountRef>
          <span class="__info_item">
            <span
              class="__info_item_title"
              translate="bulkPayment.bankAccount"
              [class.is-hu]="(currentLang$ | async) === 'hu'">
            </span>
            <span class="__info_item_text">{{ bankAccount }}</span>
          </span>
        </ng-template>

        <span class="__info_item">
          <span
            class="__info_item_title"
            translate="bulkPayment.bankName"
            [class.is-hu]="(currentLang$ | async) === 'hu'">
          </span>
          <span class="__info_item_text">{{ bankName }}</span>
        </span>

        <span class="__info_item" *ngIf="!(isCz$ | async)">
          <span
            class="__info_item_title"
            translate="bulkPayment.desc"
            [class.is-hu]="(currentLang$ | async) === 'hu'">
          </span>
          <span class="__info_item_text">VS/{{ varSymbol }}</span>
        </span>

      </div>

      <div
        fxFlex="40"
        fxFlex.xs="80"
        class="__invoices"
        [ngClass.xs]="'is-wrapped'"
        *ngIf="(unpaidInvoices$ | async) as invoices">

        <span
          class="__sub-title  __sub-title--margin-left"
          translate="bulkPayment.toBePaid">
        </span>

        <vshcz-scrollable [maxHeight]="81 * 3">

          <a
            target="_blank"
            class="__item"
            [routerLink]="[ '/', 'invoices', 'detail', invoice.id]"
            *ngFor="let invoice of invoices; let last = last">
            <vshcz-invoice-item
              [created]="invoice.created"
              [currencyId]="invoice.currencyId"
              [currencyMap]="currencyMap$ | async"
              [daysOverdue]="invoice.daysOverdue"
              [dueDate]="invoice.dueDate"
              [id]="invoice.id"
              [number]="invoice.number"
              [paidDate]="invoice.paidDate"
              [totalDue]="invoice.totalDue"
              [status]="invoice.status"
              [symbolSize]="13"
              [class.is-last]="last">
            </vshcz-invoice-item>
          </a>

        </vshcz-scrollable>

      </div>

    </div>

    <button
      class="__close"
      mat-icon-button
      [zpsNgrxDialogClose]="dialogKey">
      <mat-icon>close</mat-icon>
    </button>

  </div>
</zps-dialog>
