<ng-container *ngIf="isAuthorized$ | async">

  <vshcz-app-bar class="__app-bar"></vshcz-app-bar>

  <vshcz-tickets-trigger
    class="__support-trigger"
    *ngIf="showTrigger$ | async">
  </vshcz-tickets-trigger>

</ng-container>

<vshcz-no-client-user-dialog></vshcz-no-client-user-dialog>

<vshcz-bulk-payment-dialog></vshcz-bulk-payment-dialog>

<vshcz-bulk-payment-dialog></vshcz-bulk-payment-dialog>

<vshcz-domain-routing-info-dialog></vshcz-domain-routing-info-dialog>

<vshcz-search></vshcz-search>

<vshcz-recaptcha></vshcz-recaptcha>

<vshcz-ws-reconnect
  *ngIf="wsReconnectingShow$ | async"
  @ws-reconnect
  class="__ws-reconnecting"
  [state]="reconnectState$ | async">
</vshcz-ws-reconnect>

<vshcz-kvm-console-connection class="__kvm-consoles">
</vshcz-kvm-console-connection>

<router-outlet></router-outlet>
