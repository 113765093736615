<ng-container *ngIf="(unpaidInvoices$ | async) as invoices">

  <ng-container  *ngIf="!!invoices.length">

    <div class="__secondary">
      <div class="__content">
        <vshcz-bulk-payment-overview
          [total]="total$ | async"
          [credit]="credit$ | async"
          [unpaidCount]="invoices.length"
          [currencyMap]="currencyMap$ | async"
          [currencyId]="invoices[0]?.currencyId"
          [qrData]="qrData$ | async">
        </vshcz-bulk-payment-overview>
      </div>
    </div>

    <mat-divider></mat-divider>

  </ng-container>

</ng-container>

<div class="__content  __content--main">
  <div
    fxLayout="row wrap"
    fxLayoutAlign="start start"
    fxLayoutGap="24px grid">

    <!-- invoices -->
    <div [fxFlex]="leftLayout">
      <h5 class="__title  c-button-title">
        <span class="c-button-title__text"
          [innerHTML]="'billingCard.latestInvoices' | translate">
        </span>

        <a
          [routerLink]="[ '/', 'invoices', 'list' ]"
          mat-stroked-button
          color="accent"
          class="__link  c-button-title__link"
          [innerHTML]="'billingCard.seeAll' | translate"
          (click)="contentClicked.emit()">
        </a>
      </h5>

      <ng-container *ngIf="(latestInvoices$ | async) as invoices">
        <ng-container *ngFor="let invoice of invoices; let last = last; trackBy: _trackBy;">
          <a
            [routerLink]="[ '/', 'invoices', 'detail', invoice.id]"
            [class.is-last]="last"
            class="__item"
            (click)="contentClicked.emit()">
            <vshcz-invoice-item
              class="__item-component"
              [id]="invoice.id"
              [number]="invoice.number"
              [totalDue]="invoice.totalDue"
              [currencyId]="invoice.currencyId"
              [created]="invoice.created"
              [status]="invoice.status"
              [daysOverdue]="invoice.daysOverdue"
              [dueDate]="invoice.dueDate"
              [paidDate]="invoice.paidDate"
              [currencyMap]="currencyMap$ | async">
            </vshcz-invoice-item>
          </a>
        </ng-container>
      </ng-container>

    </div>

    <!-- payments -->
    <div [fxFlex]="rightLayout">
      <h5 class="__title  c-button-title">
        <span
          class="c-button-title__text"
          [innerHTML]="'billingCard.lastPayments' | translate">
        </span>
        <a
          [routerLink]="[ '/', 'payments', 'list' ]"
          mat-stroked-button
          (click)="contentClicked.emit()"
          color="accent"
          class="__link  c-button-title__link"
          [innerHTML]="'billingCard.seeAll' | translate">
        </a>
      </h5>

      <ng-container *ngIf="(latestPayments$ | async) as payments">
        <ng-container *ngFor="let payment of payments; let last = last; trackBy: _trackBy;">
          <a
            [routerLink]="[ '/', 'payments', 'detail', payment.id]"
            [class.is-last]="last"
            class="__item"
            (click)="contentClicked.emit()">
            <vshcz-payment-item
              class="__item-component"
              [id]="payment.id"
              [amount]="payment.amount"
              [currencyId]="payment.currencyId"
              [created]="payment.created"
              [status]="payment.status"
              [currencyMap]="currencyMap$ | async">
            </vshcz-payment-item>
          </a>
        </ng-container>
      </ng-container>

    </div>

  </div>
</div>
