import { Component } from '@angular/core';
import {
  trigger,
  transition,
  style,
  animate
} from '@angular/animations';
import { Store, select } from '@ngrx/store';
import { PermissionService } from '@zerops/fe/permission';
import { map, audit } from 'rxjs/operators';
import { combineLatest, interval } from 'rxjs';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { environment } from 'environments/environment';
import {
  Roles,
  CheckSavedToken,
  authActiveClientUser
} from '@app/base/auth-base';
import { showTicketsTrigger } from '@app/common/tickets-trigger';
import { wsReconnecting } from '@app/common/websockets';
import { cloudnsWsReconnecting } from '@app/common/clouddns-websockets';
import { State } from './models';

@Component({
  selector: 'vshcz-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('ws-reconnect', [

      transition(':enter', [
        style({
          opacity: 0,
          transform: 'translate3d(0, 4px, 0)'
        }),
        animate(200, style({
          opacity: 1,
          transform: 'translate3d(0, 0, 0)'
        }))
      ]),

      transition(':leave', [
        style({
          opacity: 1,
          transform: 'translate3d(0, 0, 0)'
        }),
        animate(200, style({
          opacity: 0,
          transform: 'translate3d(0, 4px, 0)'
        }))
      ])
    ]),
  ]
})
export class AppComponent {
  isAuthorized$ = this._permission.authorize({
    only: [ Roles.Authorized ]
  });

  activeUserClient$ = this._store.pipe(select(authActiveClientUser));
  showTicketsTrigger$ = this._store.pipe(select(showTicketsTrigger));

  showTrigger$ = combineLatest(
    this.activeUserClient$,
    this.showTicketsTrigger$
  )
    .pipe(
      map(([ userClient, showTrigger ]) => userClient
        && !!userClient.authorizedToSendTickets
        && showTrigger
      )
    );

  wsReconnecting$ = this._store.pipe(
    select(wsReconnecting)
  );

  cloudnsWsReconnecting$ = this._store.pipe(
    select(cloudnsWsReconnecting)
  );

  reconnecting$ = combineLatest(
    this.wsReconnecting$,
    this.cloudnsWsReconnecting$
  ).pipe(
    map(([ wsR, cdnsWsR ]) => wsR || cdnsWsR)
  );

  reconnectState$ = this.reconnecting$.pipe(
    map((reconnecting) => reconnecting ? '1' : '0')
  );

  wsReconnectingShow$ = this.reconnecting$.pipe(
    audit((val) => interval(!!val ? 10000 : 2000))
  );

  constructor(
    private _permission: PermissionService,
    private _store: Store<State>,
    private _angulartics: Angulartics2GoogleTagManager
  ) {

    if (environment.production) {
      this._angulartics.startTracking();
    }

    this._store.dispatch(new CheckSavedToken());
  }
}
