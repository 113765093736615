import * as tslib_1 from "tslib";
import { Store, select } from '@ngrx/store';
import { BaseClass } from '@zerops/fe/core';
import { getDialogState } from '@zerops/fe/dialog';
import { combineLatest } from 'rxjs';
import { shareReplay, map, takeUntil, filter, startWith } from 'rxjs/operators';
import { unpaidInvoices, orderInvoices } from '@app/base/invoices-base';
import { activeUserClient } from '@app/base/auth-base/auth-base.selector';
import { getPaymentQR } from '@app/base/payments-base/payments-base.utils';
import { currencyMap } from '../settings';
import { DialogKey } from './bulk-payment-dialog.constant';
import { TranslateService } from '@ngx-translate/core';
var BulkPaymentDialogContainer = /** @class */ (function (_super) {
    tslib_1.__extends(BulkPaymentDialogContainer, _super);
    function BulkPaymentDialogContainer(_store, _translate) {
        var _this = _super.call(this) || this;
        _this._store = _store;
        _this._translate = _translate;
        _this.dialogKey = DialogKey;
        _this.currencyMap$ = _this._store.pipe(select(currencyMap), shareReplay());
        _this.unpaidInvoices$ = _this._store.pipe(select(unpaidInvoices), map(function (invoices) { return orderInvoices(invoices, false); }));
        _this.firstUnpaidInvoice$ = _this.unpaidInvoices$.pipe(map(function (invoices) { return invoices && invoices.length
            ? invoices[0]
            : undefined; }));
        _this.total$ = _this.unpaidInvoices$.pipe(map(function (invoices) { return !!invoices && !!invoices.length
            ? invoices.reduce(function (sum, inv) {
                return sum + inv.totalDue;
            }, 0)
            : 0; }));
        _this.open$ = _this._store.pipe(select(getDialogState(_this.dialogKey)), map(function (_a) {
            var state = _a.state;
            return !!state;
        }));
        _this.client$ = _this._store.pipe(select(activeUserClient));
        _this.credit$ = _this.client$.pipe(map(function (client) { return client && client.creditVat
            ? client.creditVat
            : 0; }));
        _this.currentLang$ = _this._translate.onLangChange.pipe(startWith(_this._translate.currentLang), map(function () { return _this._translate.currentLang; }));
        _this.isCz$ = combineLatest(_this.client$, _this.firstUnpaidInvoice$)
            .pipe(map(function (_a) {
            var client = _a[0], invoice = _a[1];
            return (!!invoice && invoice.currencyId === 'CZK')
                && (!!client && !!client.invoiceAddress && client.invoiceAddress.countryId === 'CZ');
        }), shareReplay());
        combineLatest(_this.firstUnpaidInvoice$, _this.total$, _this.client$, _this.isCz$, _this.credit$)
            .pipe(takeUntil(_this._ngOnDestroy$), filter(function (_a) {
            var invoice = _a[0], total = _a[1], client = _a[2];
            return !!invoice && !!total && !!client;
        }))
            .subscribe(function (_a) {
            var invoice = _a[0], total = _a[1], client = _a[2], isCz = _a[3], credit = _a[4];
            // set sync data
            _this.varSymbol = client.invoiceVariableSymbol;
            _this.currencyId = invoice.currencyId;
            _this.iban = invoice.contractorBankAccount.iban;
            _this.swift = invoice.contractorBankAccount.swift;
            _this.bankAccount = invoice.contractorBankAccount.localAccountNumber;
            _this.bankName = invoice.contractorBankAccount.bankName;
            _this.qrData = getPaymentQR(_this.iban, +(total - credit).toFixed(2), _this.currencyId, isCz ? _this.varSymbol : null, _this.swift, isCz ? '' : "VS/" + _this.varSymbol);
        });
        return _this;
    }
    return BulkPaymentDialogContainer;
}(BaseClass));
export { BulkPaymentDialogContainer };
