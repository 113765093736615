import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SnackService {
  constructor(
    private _snack: MatSnackBar,
    private _translate: TranslateService
  ) { }

  fail$(
    message: string,
    close?: string,
    duration?: number,
    position?: MatSnackBarHorizontalPosition
  ) {
    return this._snack.open(
      message,
      close,
      {
        duration: duration || 5000,
        horizontalPosition: position || 'left',
        panelClass: 'c-vshcz-fail-snack'
      }
    )
    .onAction();
  }

  success$(message: string, close?: string) {
    return this._snack.open(
      message,
      close,
      {
        duration: 5000,
        horizontalPosition: 'left',
        panelClass: 'c-vshcz-success-snack'
      }
    )
    .onAction();
  }

  warning$(message: string, close?: string) {
    return this._snack.open(
      message,
      close,
      {
        duration: 5000,
        horizontalPosition: 'left',
        panelClass: 'c-vshcz-warning-snack'
      }
    )
    .onAction();
  }

  info$(message: string, close?: string) {
    return this._snack.open(
      message,
      close,
      {
        duration: 500000,
        horizontalPosition: 'center',
        panelClass: 'c-vshcz-info-snack'
      }
    )
    .onAction();
  }

  translatedSuccess$(messageKey: string, closeKey?: string, data?: { [key: string]: any; }) {
    const keys = [ messageKey, closeKey ];

    return this._translate
      .get(keys, data)
      .pipe(switchMap((translations) => this.success$(
        translations[messageKey],
        translations[closeKey]
      )));
  }

  translatedFail$(message: string, closeKey: string) {
    return this._translate
      .get(closeKey)
      .pipe(switchMap((keyTranslation) => this.fail$(
        message,
        keyTranslation
      )));
  }

  fullyTranslatedSuccess$(messageKey: string, closeKey?: string, data?: { [key: string]: any; }) {
    const keys = [ messageKey, closeKey ];

    return this._translate
      .get(keys, data)
      .pipe(switchMap((translations) => this.fail$(
        translations[messageKey],
        translations[closeKey]
      )));
  }

  translatedWarning$(messageKey: string, closeKey?: string) {
    const keys = [ messageKey, closeKey ];

    return this._translate
      .get(keys)
      .pipe(switchMap((translations) => this.warning$(
        translations[messageKey],
        translations[closeKey]
      )));
  }

}
