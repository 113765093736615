import { createFeatureSelector, createSelector } from '@ngrx/store';
import orderBy from 'lodash-es/orderBy';
import { InvoicesBaseState, InvoiceEntity } from './invoices-base.model';
import { ModuleTokens, InvoiceStatuses } from './invoices-base.constant';
import { denormalizeList } from './invoices-base.utils';

export const slice = createFeatureSelector<InvoicesBaseState>(ModuleTokens.Name);

export const entities = createSelector(
  slice,
  (s) => s.entities
);

export const listIds = createSelector(
  slice,
  (s) => s.list
);

export const listEntities = createSelector(
  listIds,
  entities,
  (ids, ents) => {

    if (!ents) { return undefined; }

    return denormalizeList(
      ids,
      ents[ModuleTokens.Name]
    ) as InvoiceEntity[];
  }
);

export const overdueInvoices = createSelector(
  listEntities,
  (invoices) => invoices && invoices.length
    ? invoices.filter((invoice) => invoice.status === InvoiceStatuses.PaymentOverdue)
    : undefined
);

export const unpaidInvoices = createSelector(
  listEntities,
  (invoices) => invoices && invoices.length
    ? invoices.filter((invoice) => invoice.status === InvoiceStatuses.PaymentOverdue
        || invoice.status === InvoiceStatuses.WaitingForPayment
      )
    : undefined
);

export const paidInvoices = createSelector(
  listEntities,
  (invoices) => invoices && invoices.length
    ? invoices.filter((invoice) => invoice.status !== InvoiceStatuses.PaymentOverdue
        && invoice.status !== InvoiceStatuses.WaitingForPayment
      )
    : undefined
);

export const oldestUnpaidInvoice = createSelector(
  listEntities,
  (invoices) => {
    const orderedInvs = orderBy(invoices, [ 'created' ], [ 'desc' ]);

    return orderedInvs.find((invoice) => invoice.status === InvoiceStatuses.PaymentOverdue
      || invoice.status === InvoiceStatuses.WaitingForPayment
    );
  }
);
