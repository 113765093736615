/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./modules/common/tickets-trigger/tickets-trigger.container.ngfactory";
import * as i3 from "./modules/common/tickets-trigger/tickets-trigger.container";
import * as i4 from "@angular/flex-layout";
import * as i5 from "@ngrx/store";
import * as i6 from "@ngx-translate/core";
import * as i7 from "@angular/cdk/scrolling";
import * as i8 from "./modules/common/app-bar/app-bar.container.ngfactory";
import * as i9 from "./modules/common/app-bar/app-bar.container";
import * as i10 from "@zerops/fe/permission";
import * as i11 from "@angular/common";
import * as i12 from "./modules/common/ws-reconnect/ws-reconnect.component.ngfactory";
import * as i13 from "./modules/common/ws-reconnect/ws-reconnect.component";
import * as i14 from "./modules/base/auth-base/modules/no-client-user-dialog/no-client-user-dialog.container.ngfactory";
import * as i15 from "./modules/base/auth-base/modules/no-client-user-dialog/no-client-user-dialog.container";
import * as i16 from "./modules/common/bulk-payment-dialog/bulk-payment-dialog.container.ngfactory";
import * as i17 from "./modules/common/bulk-payment-dialog/bulk-payment-dialog.container";
import * as i18 from "./modules/common/domain-routing-info-dialog/domain-routing-info-dialog.container.ngfactory";
import * as i19 from "./modules/common/domain-routing-info-dialog/domain-routing-info-dialog.container";
import * as i20 from "./modules/common/search/search.container.ngfactory";
import * as i21 from "./modules/common/search/search.container";
import * as i22 from "@balticcode/ngx-hotkeys";
import * as i23 from "./modules/common/recaptcha/recaptcha.component.ngfactory";
import * as i24 from "./modules/common/recaptcha/recaptcha.component";
import * as i25 from "ng-recaptcha/recaptcha/recaptcha-loader.service";
import * as i26 from "./modules/common/kvm-console-connection/kvm-console-connection.container.ngfactory";
import * as i27 from "./modules/common/kvm-console-connection/kvm-console-connection.container";
import * as i28 from "./modules/common/kvm-console-connection/kvm-console-connection.service";
import * as i29 from "./services/local-storage.service";
import * as i30 from "@angular/router";
import * as i31 from "./app.component";
import * as i32 from "angulartics2/gtm";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: { "animation": [{ type: 7, name: "ws-reconnect", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: 0, transform: "translate3d(0, 4px, 0)" }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 1, transform: "translate3d(0, 0, 0)" }, offset: null }, timings: 200 }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 6, styles: { opacity: 1, transform: "translate3d(0, 0, 0)" }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 0, transform: "translate3d(0, 4px, 0)" }, offset: null }, timings: 200 }], options: null }], options: {} }] } });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vshcz-tickets-trigger", [["class", "__support-trigger"]], [[2, "is-mobile", null]], [["document", "keydown.esc"]], function (_v, en, $event) { var ad = true; if (("document:keydown.esc" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onDocumentKeydownEsc() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_TicketsTriggerContainer_0, i2.RenderType_TicketsTriggerContainer)), i1.ɵdid(1, 245760, null, 0, i3.TicketsTriggerContainer, [i4.ObservableMedia, i5.Store, i6.TranslateService, i7.ViewportRuler], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classIsMobile; _ck(_v, 0, 0, currVal_0); }); }
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "vshcz-app-bar", [["class", "__app-bar"]], null, null, null, i8.View_AppBarContainer_0, i8.RenderType_AppBarContainer)), i1.ɵdid(2, 245760, null, 0, i9.AppBarContainer, [i4.ObservableMedia, i10.PermissionService, i5.Store], null, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AppComponent_2)), i1.ɵdid(4, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i11.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.showTrigger$)); _ck(_v, 4, 0, currVal_0); }, null); }
function View_AppComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "vshcz-ws-reconnect", [["class", "__ws-reconnecting"]], [[24, "@ws-reconnect", 0]], null, null, i12.View_WsReconnectComponent_0, i12.RenderType_WsReconnectComponent)), i1.ɵdid(1, 49152, null, 0, i13.WsReconnectComponent, [], { state: [0, "state"] }, null), i1.ɵpid(131072, i11.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.reconnectState$)); _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_AppComponent_1)), i1.ɵdid(1, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i11.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 1, "vshcz-no-client-user-dialog", [], null, null, null, i14.View_NoClientUserDialogContainer_0, i14.RenderType_NoClientUserDialogContainer)), i1.ɵdid(4, 245760, null, 0, i15.NoClientUserDialogContainer, [i5.Store], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "vshcz-bulk-payment-dialog", [], null, null, null, i16.View_BulkPaymentDialogContainer_0, i16.RenderType_BulkPaymentDialogContainer)), i1.ɵdid(6, 245760, null, 0, i17.BulkPaymentDialogContainer, [i5.Store, i6.TranslateService], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "vshcz-bulk-payment-dialog", [], null, null, null, i16.View_BulkPaymentDialogContainer_0, i16.RenderType_BulkPaymentDialogContainer)), i1.ɵdid(8, 245760, null, 0, i17.BulkPaymentDialogContainer, [i5.Store, i6.TranslateService], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "vshcz-domain-routing-info-dialog", [], null, null, null, i18.View_DomainRoutingInfoDialogContainer_0, i18.RenderType_DomainRoutingInfoDialogContainer)), i1.ɵdid(10, 245760, null, 0, i19.DomainRoutingInfoDialogContainer, [i5.Store], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "vshcz-search", [], null, [["document", "keydown.esc"]], function (_v, en, $event) { var ad = true; if (("document:keydown.esc" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onDocumentKeydownEsc() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i20.View_SearchContainer_0, i20.RenderType_SearchContainer)), i1.ɵdid(12, 4440064, null, 0, i21.SearchContainer, [i5.Store, i22.NgxHotkeysService, i6.TranslateService], null, null), (_l()(), i1.ɵeld(13, 0, null, null, 1, "vshcz-recaptcha", [], null, null, null, i23.View_RecaptchaComponent_0, i23.RenderType_RecaptchaComponent)), i1.ɵdid(14, 245760, null, 0, i24.RecaptchaComponent, [i5.Store, i25.RecaptchaLoaderService, i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AppComponent_3)), i1.ɵdid(16, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i11.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(18, 0, null, null, 1, "vshcz-kvm-console-connection", [["class", "__kvm-consoles"]], null, null, null, i26.View_KvmConsoleConnectionContainer_0, i26.RenderType_KvmConsoleConnectionContainer)), i1.ɵdid(19, 4440064, null, 0, i27.KvmConsoleConnectionContainer, [i5.Store, i28.KvmConsoleConnectionService, i29.LocalStorageService, i10.PermissionService], null, null), (_l()(), i1.ɵeld(20, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(21, 212992, null, 0, i30.RouterOutlet, [i30.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.isAuthorized$)); _ck(_v, 1, 0, currVal_0); _ck(_v, 4, 0); _ck(_v, 6, 0); _ck(_v, 8, 0); _ck(_v, 10, 0); _ck(_v, 12, 0); _ck(_v, 14, 0); var currVal_1 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform(_co.wsReconnectingShow$)); _ck(_v, 16, 0, currVal_1); _ck(_v, 19, 0); _ck(_v, 21, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vshcz-app", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i31.AppComponent, [i10.PermissionService, i5.Store, i32.Angulartics2GoogleTagManager], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("vshcz-app", i31.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
