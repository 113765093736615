import { applyEntities } from '@zerops/fe/core';
import { ModuleTokens } from './invoices-base.constant';
import { InvoicesBaseState } from './invoices-base.model';
import {
  Actions,
  ActionTypes
} from './invoices-base.action';

const initialState = new InvoicesBaseState();

export function reducer(
  state = initialState,
  action: Actions
): InvoicesBaseState {
  state = applyEntities<InvoicesBaseState>(
    state,
    [ ModuleTokens.Name ],
    action
  );

  if (action.type === ActionTypes.ListLocalSuccess) {
    return {
      ...state,
      list: action.payload.result
    };
  }

  return state;
}
