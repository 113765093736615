export enum InvoiceStatuses {
  PaymentOverdue = 'PAYMENT_OVERDUE',
  WaitingForPayment = 'WAITING_FOR_PAYMENT',
  Paid = 'PAID',
  Canceled = 'CANCELED'
}

export enum ModuleTokens {
  Name = 'invoicesBase'
}

export enum InvoiceTypes {
  clientServices = 'CLIENT_SERVICES',
  credit = 'CREDIT',
  fee = 'FEE'
}

export const INVOICE_ORDER_PRIORITY_MAP = {
  [InvoiceStatuses.PaymentOverdue]: 2,
  [InvoiceStatuses.WaitingForPayment]: 1,
  [InvoiceStatuses.Paid]: 0,
  [InvoiceStatuses.Canceled]: 0
};
