  <mat-icon class="__item-type-icon  __invoice-icon">call_received</mat-icon>

  <span class="__item_main">
    <vshcz-price
      class="__total-vat"
      [priceSize]="priceSize"
      [symbolSize]="symbolSize"
      [amount]="totalDue"
      [currencyId]="currencyId"
      [currencyMap]="currencyMap">
    </vshcz-price>
  </span>

  <span class="__item_secondary">

    <vshcz-invoice-dates-info
      class="__dates"
      [status]="status"
      [created]="created"
      [dueDate]="dueDate"
      [daysOverdue]="daysOverdue"
      [paidDate]="paidDate">
    </vshcz-invoice-dates-info>

    <span class="__id"># <strong>{{ number }}</strong></span>

  </span>

  <vshcz-invoice-status
    class="__status"
    [status]="status"
    [tooltip]="'invoiceStatuses.tooltips.' + status | translate">
  </vshcz-invoice-status>
