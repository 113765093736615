import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { BaseClass } from '@zerops/fe/core';
import { select, Store } from '@ngrx/store';
import { map, shareReplay, filter, takeUntil } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import orderBy from 'lodash-es/orderBy';
import { paymentsListEntities } from '@app/base/payments-base';
import { invoicesListEntities, unpaidInvoices, } from '@app/base/invoices-base';
import { currencyMap } from '@app/common/settings';
import { getPaymentQR } from '@app/base/payments-base/payments-base.utils';
import { activeUserClient } from '@app/base/auth-base/auth-base.selector';
var BillingCardContainer = /** @class */ (function (_super) {
    tslib_1.__extends(BillingCardContainer, _super);
    function BillingCardContainer(_store) {
        var _this = _super.call(this) || this;
        _this._store = _store;
        // -- async
        _this.latestPayments$ = _this._store.pipe(select(paymentsListEntities), map(function (payments) { return orderBy(payments, ['created'], ['desc']).slice(0, 5); }));
        _this.latestInvoices$ = _this._store.pipe(select(invoicesListEntities), map(function (invoices) {
            if (invoices) {
                return invoices.slice(0, 5);
            }
            return invoices;
        }));
        _this.unpaidInvoices$ = _this._store.pipe(select(unpaidInvoices));
        _this.total$ = _this.unpaidInvoices$.pipe(filter(function (d) { return !!d; }), map(function (invoices) { return invoices.reduce(function (sum, inv) { return sum + inv.totalDue; }, 0); }));
        _this.client$ = _this._store.pipe(select(activeUserClient));
        _this.credit$ = _this.client$.pipe(map(function (client) { return client && client.creditVat
            ? client.creditVat
            : 0; }));
        _this.qrData$ = combineLatest(_this.unpaidInvoices$.pipe(map(function (invoices) { return !!invoices && !!invoices.length ? invoices[0] : undefined; })), _this.total$, _this.client$, _this.credit$)
            .pipe(map(function (_a) {
            var invoice = _a[0], total = _a[1], client = _a[2], credit = _a[3];
            return !!invoice && !!total && !!client
                ? getPaymentQR(invoice.contractorBankAccount.iban, +(total - credit).toFixed(2), invoice.currencyId, invoice.currencyId === 'CZK' && client.invoiceAddress.countryId === 'CZ'
                    ? client.invoiceVariableSymbol
                    : null, invoice.contractorBankAccount.swift, invoice.currencyId === 'CZK' && client.invoiceAddress.countryId === 'CZ'
                    ? ''
                    : "VS/" + client.invoiceVariableSymbol)
                : '';
        }));
        _this.currencyMap$ = _this._store.pipe(select(currencyMap), shareReplay());
        // -- angular
        _this.contentUpdated = new EventEmitter();
        _this.contentClicked = new EventEmitter();
        // emit that content changed so menu can resize the pop
        combineLatest(_this.latestInvoices$, _this.latestPayments$)
            .pipe(takeUntil(_this._ngOnDestroy$))
            .subscribe(function () { return _this.contentUpdated.emit(); });
        return _this;
    }
    BillingCardContainer.prototype._trackBy = function (index) {
        return index;
    };
    return BillingCardContainer;
}(BaseClass));
export { BillingCardContainer };
