import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { NgxQRCodeModule } from 'ngx-qrcode2';
import { TranslateModule } from '@ngx-translate/core';
import { PaymentItemModule } from '@app/common/payment-item';
import { InvoiceItemModule } from '@app/common/invoice-item';
import { BulkPaymentOverviewModule } from '../bulk-payment-overview';
import { BillingCardContainer } from './billing-card.container';

@NgModule({
  declarations: [ BillingCardContainer ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    FlexLayoutModule,
    NgxQRCodeModule,
    TranslateModule.forChild(),
    PaymentItemModule,
    InvoiceItemModule,
    BulkPaymentOverviewModule
  ],
  exports: [ BillingCardContainer ]
})
export class BillingCardModule { }
